import { useState } from 'react'
import { getCompanyWorkspaces } from 'src/services/workspaces'
import { Workspace } from 'src/types/workspace'

export const useWorkspace = () => {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const fetchWorkspaces = async () => {
    try {
      const response = await getCompanyWorkspaces({
        offset: 0,
        limit: 1000
      })

      setWorkspaces(response.data)
      return response.data
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }
  return {
    fetchWorkspaces,
    workspaces,
    loading,
    error
  }
}
